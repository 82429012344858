.bigtitle {
    font-size: 60px;
    margin: 15px;
}

.flexible{

    display:flex;
}


.leftext{

    text-align: left!important;
}

.rightext{
    
        text-align: right!important;
    }

.column{

    margin:auto;
    width:50%;

}

.one-column{

margin:auto;
    width:70%;

}

.column {
    margin: auto;
    width: 50%;
    padding: 5%;
}


.illustration{
    
        width:100%
    }

.biggertitle{


font-size:40px;


}

.mini-icons {

    transform: translate(-5px, 5px);
}



.diapo {
    padding-top: 160px;
    padding-bottom: 200px;
}

.arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }