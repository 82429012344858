@import url('https://fonts.googleapis.com/css?family=Libre+Caslon+Text:400,700&display=swap');



.hamburger-icon {
  position: absolute;
  z-index: 1;
  top: 5vh;
  left: 5vw;
  padding-bottom: 2vh;
}



.hamburger-icon span {
  height: 5px;
  width: 40px;
  background-color: white;
  display: block;
  margin: 5px 0px 5px 0px;
  transition: 0.7s ease-in-out;
  transform: none;
}

#openmenu:checked ~ .menu-pane {
  left: -5vw;
  transform: translateX(-5vw);

}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(2) {
  transform: translate(0%, 175%) rotate(-45deg);
  background-color: white;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(3) {
  transform: rotate(45deg);
  background-color: white;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(1) {
  opacity: 0;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(4) {
  opacity: 0;
}

div.menu-pane {
  background-color: #000;
  position: absolute;
  transform: translateX(-145vw);
  transform-origin: (0, 0);
  width: 120vw;
  height: 100%;
  transition: 0.6s ease-in-out;
  top:0px;
}

.menu-pane p {
  color: black;
  font-size: 0.6em;
}

.menu-pane nav {
    padding: 10%;
    background-color: #000000db;
    height:1500px;
}





.menu-links li, a, span {
      transition: 0.5s ease-in-out;
}

.menu-pane ul {
  display: inline-block;
  margin-left: 70px;
}
.menu-pane li {

    font-size: 1em;
}





.menu-links li a {
  color: white;
  text-decoration: none;
}


.menu-links li:hover a {
  color: #FFAB91;
}





.menu-links li:first-child:hover a {
  color: black;  
  background-color: #FFAB91;
}



.menu-links li:first-child:hover #QC-info {
opacity: 1;
}

.menu-links li:first-child:hover #DC-info {
opacity: 1;
}



.menu-links li:first-child a {
  padding: 5px;
}



input.hamburger-checkbox {
  position: absolute;
  z-index: 3;
  top: 5vh;
  left: 5vw;

  opacity: 0;

}

ul.menu-links {
    color: white;
    text-align: left;
    float: left;
}

.menu-pane li {
    list-style: none;
    margin-bottom: 35%;
    font-size: 30px;
}

    @media screen and (max-width: 1000px)  {
menu#menumobile {

    display:block;
    width: 100%;
    height: 95px;
}




    }