
   @media screen and (max-width: 600px)  {

.flexible {
   display: block;
}


#banner {

   padding-top: 40%;
   padding-bottom: 46%;
}

   }