button {
    padding: 12px;
    border-radius: 100px;
    background-image: linear-gradient( 
45deg, #9A00D7, #06DCCC);
    min-width: 150px;
    color: white;
    font-weight: bolder;
    font-size: 17px;
    margin-top:25px;
    border:none;
}