@import url(https://fonts.googleapis.com/css?family=Libre+Caslon+Text:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Counter_row__2bfBn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Counter_row__2bfBn > button {
  margin-left: 4px;
  margin-right: 8px;
}
.Counter_row__2bfBn:not(:last-child) {
  margin-bottom: 16px;
}

.Counter_value__23suT {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.Counter_button__2Zcrg {
  -webkit-appearance: none;
          appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.Counter_textbox__NI6cH {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 4px;
}

.Counter_button__2Zcrg:hover,
.Counter_button__2Zcrg:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.Counter_button__2Zcrg:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.Counter_asyncButton__2t_rA {
  position: relative;
}

.Counter_asyncButton__2t_rA:after {
  content: '';
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.Counter_asyncButton__2t_rA:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}




menu {
    background-color: black;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    width: 100%;
    position: fixed;
    margin: 0px;
    display: flex;
    top:0px;
}




.logo {
    height: 40px;
    padding-top:10px;
    padding-bottom: 20px;
}


menu > ul li{


    display:inline;
    color:white;
    margin: 15px;
}

.space-menu{

    width:30%;
}

menu#menumobile {
    
        display:none;
     
    }

    li#selectlanguage {
        border: solid 2px;
        border-radius: 20px;
        padding: 8px;
    }
    
    li#login-connect {
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 20px;
    }


@media screen and (max-width: 1400px)  {
    .space-menu {
        width: 30%;
    }
    
    }


@media screen and (max-width: 1100px)  {
.space-menu {
    width: 8%;
}

}

@media screen and (max-width: 1000px)  {
#menudesktop{

    display:none;


}

}
#banner {
   
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 30px;
    color: white;
    padding-top: 15%;
    padding-bottom: 20%;
    background-color: black;
    background-image: url(/static/media/banner.c2dcf7cb.jpg);
    
}

section#price {
    background-image: url(/static/media/web-agency.d496fef4.jpg);
    color: white;
}

.social-media {

    position: fixed;
    bottom:0px;
    margin-bottom:55px;

}

.social-media li {
    text-align: left;
    list-style: none;
    padding-top: 20px;
}
#banner p {
    max-width: 90%;
    margin: auto;
}
ul.footer-menu li {

    margin: 25px;
    text-align: left;
    list-style: none;
}


ul.footer-menu {
    margin: auto;
    height: 315px;
}

footer {
    color: white;
    background-color: black;
    padding-top: 40px;
    padding-bottom: 70px;
    background-image: url(/static/media/ouiidy-logo.9f7a7a60.png);
    background-repeat: no-repeat;
    background-position: 500px 85px;
}

.copyright {
    margin-top: 55px;
}
.bigtitle {
    font-size: 60px;
    margin: 15px;
}

.flexible{

    display:flex;
}


.leftext{

    text-align: left!important;
}

.rightext{
    
        text-align: right!important;
    }

.column{

    margin:auto;
    width:50%;

}

.one-column{

margin:auto;
    width:70%;

}

.column {
    margin: auto;
    width: 50%;
    padding: 5%;
}


.illustration{
    
        width:100%
    }

.biggertitle{


font-size:40px;


}

.mini-icons {

    -webkit-transform: translate(-5px, 5px);

            transform: translate(-5px, 5px);
}



.diapo {
    padding-top: 160px;
    padding-bottom: 200px;
}

.arrow {
    border: solid rgb(255, 255, 255);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
button {
    padding: 12px;
    border-radius: 100px;
    background-image: linear-gradient( 
45deg, #9A00D7, #06DCCC);
    min-width: 150px;
    color: white;
    font-weight: bolder;
    font-size: 17px;
    margin-top:25px;
    border:none;
}

@font-face {
    font-family: "omnes";
    src: url(/static/media/omnes.14a06558.ttf);

          
   }


   body{

    font-family: "omnes";


   }



@media screen and (max-width: 1000px)  {

ul.footer-menu li {
    display: inline;
    margin: 20px;
}

}
.hamburger-icon {
  position: absolute;
  z-index: 1;
  top: 5vh;
  left: 5vw;
  padding-bottom: 2vh;
}



.hamburger-icon span {
  height: 5px;
  width: 40px;
  background-color: white;
  display: block;
  margin: 5px 0px 5px 0px;
  transition: 0.7s ease-in-out;
  -webkit-transform: none;
          transform: none;
}

#openmenu:checked ~ .menu-pane {
  left: -5vw;
  -webkit-transform: translateX(-5vw);
          transform: translateX(-5vw);

}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(2) {
  -webkit-transform: translate(0%, 175%) rotate(-45deg);
          transform: translate(0%, 175%) rotate(-45deg);
  background-color: white;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(3) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: white;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(1) {
  opacity: 0;
}

#openmenu:checked ~ .hamburger-icon span:nth-of-type(4) {
  opacity: 0;
}

div.menu-pane {
  background-color: #000;
  position: absolute;
  -webkit-transform: translateX(-145vw);
          transform: translateX(-145vw);
  -webkit-transform-origin: (0, 0);
          transform-origin: (0, 0);
  width: 120vw;
  height: 100%;
  transition: 0.6s ease-in-out;
  top:0px;
}

.menu-pane p {
  color: black;
  font-size: 0.6em;
}

.menu-pane nav {
    padding: 10%;
    background-color: #000000db;
    height:1500px;
}





.menu-links li, a, span {
      transition: 0.5s ease-in-out;
}

.menu-pane ul {
  display: inline-block;
  margin-left: 70px;
}
.menu-pane li {

    font-size: 1em;
}





.menu-links li a {
  color: white;
  text-decoration: none;
}


.menu-links li:hover a {
  color: #FFAB91;
}





.menu-links li:first-child:hover a {
  color: black;  
  background-color: #FFAB91;
}



.menu-links li:first-child:hover #QC-info {
opacity: 1;
}

.menu-links li:first-child:hover #DC-info {
opacity: 1;
}



.menu-links li:first-child a {
  padding: 5px;
}



input.hamburger-checkbox {
  position: absolute;
  z-index: 3;
  top: 5vh;
  left: 5vw;

  opacity: 0;

}

ul.menu-links {
    color: white;
    text-align: left;
    float: left;
}

.menu-pane li {
    list-style: none;
    margin-bottom: 35%;
    font-size: 30px;
}

    @media screen and (max-width: 1000px)  {
menu#menumobile {

    display:block;
    width: 100%;
    height: 95px;
}




    }

   @media screen and (max-width: 600px)  {

.flexible {
   display: block;
}


#banner {

   padding-top: 40%;
   padding-bottom: 46%;
}

   }

    @media screen and (max-width: 800px)  {


ul.footer-menu li {
    margin: 25px;
    display: block;
    text-align: center;
    list-style: none;
}


    }
