


menu {
    background-color: black;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    position: fixed;
    margin: 0px;
    display: flex;
    top:0px;
}




.logo {
    height: 40px;
    padding-top:10px;
    padding-bottom: 20px;
}


menu > ul li{


    display:inline;
    color:white;
    margin: 15px;
}

.space-menu{

    width:30%;
}

menu#menumobile {
    
        display:none;
     
    }

    li#selectlanguage {
        border: solid 2px;
        border-radius: 20px;
        padding: 8px;
    }
    
    li#login-connect {
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 20px;
    }


@media screen and (max-width: 1400px)  {
    .space-menu {
        width: 30%;
    }
    
    }


@media screen and (max-width: 1100px)  {
.space-menu {
    width: 8%;
}

}

@media screen and (max-width: 1000px)  {
#menudesktop{

    display:none;


}

}