
    @media screen and (max-width: 800px)  {


ul.footer-menu li {
    margin: 25px;
    display: block;
    text-align: center;
    list-style: none;
}


    }