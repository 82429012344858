#banner {
   
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-top: 30px;
    color: white;
    padding-top: 15%;
    padding-bottom: 20%;
    background-color: black;
    background-image: url(../../images/banner.jpg);
    
}

section#price {
    background-image: url(../../images/web-agency.jpg);
    color: white;
}

.social-media {

    position: fixed;
    bottom:0px;
    margin-bottom:55px;

}

.social-media li {
    text-align: left;
    list-style: none;
    padding-top: 20px;
}
#banner p {
    max-width: 90%;
    margin: auto;
}