
@font-face {
    font-family: "omnes";
    src: url("omnes.ttf") ;

          
   }


   body{

    font-family: "omnes";


   }