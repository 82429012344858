ul.footer-menu li {

    margin: 25px;
    text-align: left;
    list-style: none;
}


ul.footer-menu {
    margin: auto;
    height: 315px;
}

footer {
    color: white;
    background-color: black;
    padding-top: 40px;
    padding-bottom: 70px;
    background-image: url(../../images/ouiidy-logo.png);
    background-repeat: no-repeat;
    background-position: 500px 85px;
}

.copyright {
    margin-top: 55px;
}